import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { usePopper } from 'react-popper'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'gatsby'
import { ModalBase } from '@livechat/design-system'

import Layout from '../../layout/live-coding-layout'
import { SEO, Benefits, Image } from '../../components'
import { decodeQueryString, canUseWindow, toggleScroll } from '../../utils'

import './live-coding.scss'

import SDKsIcon from '../../assets/icons/sdks-gray.svg'
import CloudIcon from '../../assets/icons/cloud-gray.svg'
import PaintIcon from '../../assets/icons/palette-brush-gray.svg'
import DiscordIcon from '../../assets/icons/discord.svg'
import LinkedinIcon from '../../assets/icons/linkedin.svg'
import GitHubIcon from '../../assets/icons/github.svg'
import LiveChatIcon from '../../assets/icons/livechat-icon.svg'
import OgImage from '../../assets/images/livechat-properties-og-image.png'

import MailChimpForm from '../../components/Forms/LiveCoding'

const HoverMe = ({ children, desc, setOverlay }) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [visible, setVisible] = useState(false)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
  })

  const handleClick = e => {
    e.preventDefault()
    setVisible(true)
    setOverlay(true)
  }

  const handleMouseOver = () => {
    setOverlay(true)
    setVisible(true)
  }

  const handleMouseOut = () => {
    setOverlay(false)
    setVisible(false)
  }

  return (
    <>
      <a
        href="#details"
        ref={setReferenceElement}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`hoverMe ${visible && 'hovered'}`}
      >
        <span
          style={{ color: '#6daaf8' }}
          className="u-text-mark u-text-mark-blue default-blue"
        >
          {children}
        </span>
      </a>

      <AnimatePresence>
        {visible && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="popper-wrapper"
          >
            <motion.div
              animate={{
                y: 0,
                opacity: 1,
                rotateX: 0,
              }}
              initial={{
                y: 10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: 4,
              }}
              exit={{
                y: -10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: -4,
              }}
              transition={{ ease: 'easeOut', duration: 0.15 }}
              className={'popperBox'}
            >
              <div className="u-px-xl">{desc}</div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

const LiveCoding = () => {
  const queryString = (canUseWindow && window.location.search) || ''
  const { action } = decodeQueryString(queryString)
  const initialState = action === 'register'
  const isWide = useMedia({ minWidth: 870 })

  const [overlay, setOverlay] = useState(initialState)
  const [isFormOpen, setIsFormOpen] = useState(action === 'open-form')

  const openForm = () => {
    setIsFormOpen(true)
    toggleScroll(false)
  }

  const closeForm = () => {
    setIsFormOpen(false)
    toggleScroll(true)
  }

  return (
    <Layout
      overlay={overlay}
      setOverlay={setOverlay}
      eventDate="July 6, 2022"
      ctaClassName="c-btn btn-signup u-text-p6-medium"
      headerCta={{
        onClick: openForm,
        label: 'Save your seat',
      }}
      shouldBlockScroll={isFormOpen}
    >
      <SEO
        title="Live Coding Session with Developer Program"
        description="Take part in a online live coding session with the LiveChat Developer Program team. Learn how to effectively implement LiveChat properties into your applications."
        image={OgImage}
      />
      <section className="u-text-center u-Pt-xl u-Pb-xl">
        <div className="o-container">
          <h2 className="u-text-p2 u-mt-0 u-mb-sm md:u-text-left u-text-center u-px-xl">
            Break down the details of Properties
          </h2>
        </div>
        <section className="o-container o-columns v--two">
          <div className="o-container u-flex u-items-center u-w-full">
            <div class="video-container">
              <iframe
                title="Live Coding Session Invitation"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Ct9hKDjMvls"
              />
            </div>
          </div>
          <div className="o-container">
            <div className="u-text-p5 u-px-xl u-mt-3xl">
              <p className="md:u-text-left u-text-center">
                Join us for a{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Code-along session</strong>
                      </p>
                      <p>
                        Follow along with coding to get the most out of the
                        session.
                      </p>
                    </div>
                  }
                >
                  live coding session
                </HoverMe>{' '}
                where we’ll showcase the
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>LiveChat properties</strong>
                      </p>
                      <p>
                        Break down the LiveChat properties — a powerful tool for
                        storing pieces of data inside of your apps, which you
                        can use instead of a complex backend.
                      </p>
                    </div>
                  }
                >
                  {' '}
                  LiveChat properties
                </HoverMe>
                . Learn how to use{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Building blocks</strong>
                      </p>
                      <p>
                        Implement our API core functions inside a
                        beginner-friendly user interface.
                      </p>
                    </div>
                  }
                >
                  building blocks{' '}
                </HoverMe>
                and see a{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Code example from a published app</strong>
                      </p>
                      <p>
                        Analyze the code of Quick Notes, an app that bases its’
                        core functionality on properties.
                      </p>
                    </div>
                  }
                >
                  live code example{' '}
                </HoverMe>
                from one of the most popular Marketplace apps, Quick Notes.
              </p>

              <p className="md:u-text-left u-text-center second-text">
                {' '}
                Become part of the LiveChat developer{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Let's meet on Discord</strong>
                      </p>
                      <p>
                        You'll have a chance to join the growing community of
                        developers and build strong connections.
                      </p>
                    </div>
                  }
                >
                  community
                </HoverMe>
                <br /> to make the most of your LiveChat account and{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Additional revenue stream</strong>
                      </p>
                      <p>
                        Selling apps or services on our Marketplace is a
                        reliable way of generating an additional revenue stream.
                      </p>
                    </div>
                  }
                >
                  monetize apps
                </HoverMe>
                .
              </p>
            </div>
          </div>
        </section>

        <div className="u-Mt-sm sm:u-Mt-xl">
          <div className="o-container">
            <div className="u-flex u-flex-col sm:u-flex-row u-flex-wrap u-justify-center u-items-center u-text-left u-text-p5 u-p-md u-bg-black2 u-rounded u-shadow md:u-Mx-lg">
              <div className="sm:u-mr-xl">
                <div className="u-text-p5 u-mb-md u-mx-auto u-Mt-sm sm:u-Mt-sm event-intro-details">
                  <strong>When:</strong>{' '}
                  <span className="event-intro-info u-text-gray-800">
                    July 6, 2022 at 5 p.m. CET
                  </span>
                </div>
                <div className="u-text-p5 u-mb-md u-mx-auto event-intro-details">
                  <strong>Where:</strong>{' '}
                  <span className="event-intro-info u-text-gray-800">
                    <a
                      href="https://youtu.be/sA5bAeZYf-8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="u-text-blue"
                    >
                      YouTube
                    </a>
                  </span>
                </div>
                <div className="u-text-p5 u-mb-md u-mx-auto u-Mb-sm sm:u-Mb-sm event-intro-details">
                  <strong>Host:</strong>{' '}
                  <span className="event-intro-info u-text-gray-800">
                    <a
                      href="https://www.linkedin.com/in/klaym3n/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="u-text-blue"
                    >
                      Michał Cichocki
                    </a>
                    , Developer Relations
                  </span>
                </div>
              </div>
              <button
                style={{ width: '208px', height: '58px' }}
                onClick={openForm}
                className="c-btn u-text-p5-medium btn-signup"
              >
                Save your seat
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="o-container u-Mb-xl o-columns v--two">
        <div className="o-container">
          <h2 className="u-text-p4">Is it for me?</h2>
          <p className="u-text-p6 u-text-gray-800">
            This session is for people who:
            <ul className="u-text-p6 u-text-gray-800 u-px-xl">
              <li className="u-mb-sm">
                Want to understand how to store pieces of data inside{' '}
                <a
                  href="https://developers.livechat.com/docs/management/configuration-api#properties/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat u-text-blue"
                >
                  LiveChat properties{' '}
                </a>{' '}
                instead of a separate and complex backend.
              </li>
              <li className="u-mb-sm">
                {' '}
                Are curios to see and analyze the code of a live production app,{' '}
                <a
                  href="https://www.livechat.com/marketplace/apps/quick-notes/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat u-text-blue"
                >
                  Quick Notes
                </a>
                , from behind the scenes.
              </li>
              <li className="u-mb-sm">
                {' '}
                Think of creating an additional revenue stream by building paid
                apps on the{' '}
                <a
                  href="https://www.livechat.com/marketplace/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat u-text-blue"
                >
                  LiveChat Marketplace
                </a>
                .
              </li>
            </ul>
          </p>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">What you'll learn</span>
          </h2>
          <ol className="u-text-p6 u-text-gray-800 u-px-xl">
            <li className="u-mb-sm">
              The concept of{' '}
              <a
                href="https://developers.livechat.com/docs/management/configuration-api#properties"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat u-text-blue"
              >
                LiveChat properties
              </a>{' '}
              and how to effectively implement them into your projects.
            </li>
            <li className="u-mb-sm">
              How to configure the{' '}
              <a
                href="https://developers.livechat.com/docs/getting-started/app-guides/properties"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat u-text-blue"
              >
                LiveChat Properties building block{' '}
              </a>
              in the{' '}
              <a
                href="https://developers.livechat.com/console"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat u-text-blue"
              >
                LiveChat Developer Console
              </a>
              .
            </li>
            <li className="u-mb-sm">
              The details of a live properties code example from an app already
              published on the LiveChat Marketplace,{' '}
              <a
                href="https://www.livechat.com/marketplace/apps/quick-notes/"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat u-text-blue"
              >
                Quick Notes
              </a>
              .
            </li>
            <li className="u-mb-sm">
              How to combine all that knowledge to build a simple app based on
              properties in the{' '}
              <a
                href="https://developers.livechat.com/console"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat u-text-blue"
              >
                Developer Console
              </a>
              .
            </li>
          </ol>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">About the host</span>
          </h2>
          <div className="o-feature u-mb-md">
            <div
              className="u-mb-xs u-flex"
              style={{
                justifyContent: isWide ? 'start' : 'center',
                alignItems: isWide ? 'start' : 'center',
              }}
            >
              <Image
                type="michalCichocki"
                className="u-round"
                wrapperStyles={{
                  width: '180px',
                }}
              />
            </div>
            <div
              class="u-ml-auto u-mr-auto u-flex u-flex-col"
              style={{
                textAlign: isWide ? 'left' : 'center',
              }}
            >
              <p className="u-text-p5 u-mb-md">Michał Cichocki</p>
              <p className="u-text-p10 u-text-gray-600">
                Developer Relations,
                <br />
                Developer Education
              </p>
              <div className="o-column v--three v--sparse">
                <a
                  href="https://discord.gg/gRzwSaCxg4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300 u-mr-xl"
                >
                  <DiscordIcon />
                </a>
                <a
                  href="https://www.linkedin.com/in/klaym3n/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300 u-mr-xl"
                >
                  <LinkedinIcon />
                </a>
                <a
                  href="https://github.com/Klaym3n"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300"
                >
                  <GitHubIcon />
                </a>
              </div>
            </div>
          </div>
          <p className="u-text-p6 u-text-gray-800">
            <a
              href="https://www.livechat.com/team/#michal-cichocki"
              target="_blank"
              rel="noopener noreferrer"
              className="u-text-blue"
            >
              Michał Cichocki
            </a>{' '}
            is a Developer Relations represenative, working with external devs
            on a daily basis. With his great experience, he solves both minor
            and advanced developer queries. Keep an eye on our{' '}
            <a
              href="https://discord.gg/gRzwSaCxg4"
              target="_blank"
              rel="noopener noreferrer"
              className="u-text-blue"
            >
              Discord server
            </a>{' '}
            if you want to have a chat with him.
          </p>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">Who we are</h2>
          <p className="u-text-p6 u-text-gray-800">
            Behind LiveChat, Inc. there is a team of{' '}
            <a
              href="https://www.livechat.com/team/"
              target="_blank"
              rel="noopener noreferrer"
              className="u-text-blue"
            >
              passionate people
            </a>{' '}
            building online customer service software with online chat, help
            desk software, chatbot, and web analytics capabilities.{' '}
          </p>
          <p className="u-text-p6 u-text-gray-800">
            With a suite of four products (
            <a
              href="https://www.livechat.com/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs6"
              target="_blank"
              rel="noopener noreferrer"
              className="u-text-blue"
            >
              LiveChat
            </a>
            ,{' '}
            <a
              href="https://www.chatbot.com/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs6"
              target="_blank"
              rel="noopener noreferrer"
              className="u-text-blue"
            >
              ChatBot
            </a>
            ,{' '}
            <a
              href="https://www.helpdesk.com/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs6"
              target="_blank"
              rel="noopener noreferrer"
              className="u-text-blue"
            >
              HelpDesk
            </a>
            ,{' '}
            <a
              href="https://www.knowledgebase.ai/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs6"
              target="_blank"
              rel="noopener noreferrer"
              className="u-text-blue"
            >
              KnowledgeBase
            </a>
            ) and their powerful APIs, we power customer communication for
            35,000 companies in 150 countries.
          </p>
          <p className="u-text-p6 u-text-gray-800">
            <Link to="/" className="u-text-blue">
              LiveChat Platform
            </Link>{' '}
            is a range of products and services that can be used to build a
            variety of communication tools for businesses. Our{' '}
            <Link to="/" className="u-text-blue">
              Developer Program
            </Link>{' '}
            and{' '}
            <a
              href="https://www.livechat.com/marketplace/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs6"
              target="_blank"
              rel="noopener noreferrer"
              className="u-text-blue"
            >
              Marketplace{' '}
            </a>
            create an open ecosystem for developers, partners, and customers.
          </p>
        </div>
      </section>

      <section className="u-bg-black2 u-Py-xl u-Mt-2xl u-Mb-md">
        <h2 className="u-text-p3 u-Mb-md u-text-center">
          Our previous sessions
        </h2>
        <Benefits
          content={[
            {
              icon: SDKsIcon,
              label: 'Building apps 101',
              iconFill: 'icon-fill-gray',
              description: (
                <>
                  See{' '}
                  <a
                    href="https://www.youtube.com/watch?v=SjqCBgAaEbc&feature=youtu.be"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-text-blue"
                  >
                    part 1
                  </a>{' '}
                  if you're just starting out with LiveChat apps or need to dust
                  off your knowledge.
                </>
              ),
            },
            {
              icon: CloudIcon,
              label: 'Authorization',
              iconFill: 'icon-fill-gray',
              description: (
                <>
                  <a
                    href="https://youtu.be/FDmLqEiCH60"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-text-blue"
                  >
                    Part 2
                  </a>{' '}
                  was dedicated to web app authorization, while server-side apps
                  were tackled in{' '}
                  <a
                    href="https://www.youtube.com/watch?v=ei8YG9mLdfI"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-text-blue"
                  >
                    part 3
                  </a>
                  .
                </>
              ),
            },
            {
              icon: PaintIcon,
              label: 'Coding in practice',
              iconFill: 'icon-fill-gray',
              description: (
                <>
                  See{' '}
                  <a
                    href="https://youtu.be/d792twx7Q0I"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-text-blue"
                  >
                    part 4
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://youtu.be/xSRsKhhM4hM"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="u-text-blue"
                  >
                    part 5
                  </a>{' '}
                  to learn all the details about customizing your app's design
                  and elevating the Chat Widget experience.
                </>
              ),
            },
          ]}
        />
        <div className="u-text-center">
          <h3 className="u-text-p5 u-Mb-md u-text-center">
            Got a question? Ask us on Discord!
            <br />
          </h3>
          <a
            style={{ color: '#131317' }}
            href="https://discord.gg/gRzwSaCxg4"
            target="_blank"
            rel="noopener noreferrer"
            className="c-btn btn-signup u-text-p6-medium"
          >
            Join Discord
          </a>
        </div>
      </section>
      <section className="u-text-center u-My-xl">
        <LiveChatIcon />
        <p className="u-text-p-bold u-mt-sm outro">
          Live coding session by the <Link to="/">LiveChat Platform</Link>
        </p>
        <p className="u-text-p8 outro">
          Our vision is to help innovators build the future of the
          communication. Sounds interesting?{' '}
          <a href="https://www.livechat.com/careers/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs6">
            Join us
          </a>
          !
        </p>
      </section>

      {isFormOpen && (
        <div className="modal-form">
          <ModalBase
            onClose={closeForm}
            style={{ width: '500px', height: '500px' }}
          >
            <div style={{ margin: 'auto', overflow: 'auto' }}>
              <MailChimpForm />
            </div>
          </ModalBase>
        </div>
      )}
    </Layout>
  )
}

HoverMe.propTypes = {
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setOverlay: PropTypes.func,
}

export default LiveCoding
